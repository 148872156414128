<template>
  <layout
    style="background: #f5f5f5;"
    title="Vender"
    :options="options"
    :rotas="rotas"
  >
    <div
      id="status_caixa"
      class="expande-horizontal column"
      :style="
        `min-height: 75vh; ${
          $vuetify.breakpoint.lgAndUp ? 'max-height: 94vh;' : '94vh'
        } ;`
      "
    >
      <v-flex
        :class="{
          'px-2': $vuetify.breakpoint.lgAndUp
        }"
        xs12
      >
        <div
          v-if="get_caixa.status_atual === 'fechado'"
          class="expande-horizontal centraliza"
          style="height: 100%;"
        >
          <v-list color="transparent" nav>
            <v-list-item
              @click="abre_modal_abertura_caixa"
              class="list-box-to-open pl-6"
            >
              <v-list-item-content>
                <v-list-item-title class="fonte fonte-bold white--text">
                  <span style="font-size: 16pt;">
                    Caixa {{ get_caixa.status_atual }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="fonte grey--text">
                  Clique aqui para abrir
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-avatar class="mr-2" color="#f2f2f2">
                  <v-icon color="#325286">mdi-cash-register</v-icon>
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <v-card
          v-else
          :outlined="$vuetify.breakpoint.lgAndUp ? true : false"
          class="expande-horizontal wrap"
          :class="{
            'pa-0 pl-0 mt-3': $vuetify.breakpoint.lgAndUp,
            'pa-0 pl-0 ma-0': $vuetify.breakpoint.smAndDown,
            'elevation-0': $vuetify.breakpoint.smAndDown
          }"
          :style="`min-height: ${$vuetify.breakpoint.lgAndUp ? 87 : 94}vh;`"
        >
          <v-flex xs12 md6>
            <div class="expande-horizontal column">
              <v-flex class="mb-3" xs12>
                <div class="pt-2 pl-3 fonte centraliza expande-horizontal">
                  <v-btn
                    rounded
                    x-small
                    @click.prevent="setTypeBuy('balcao')"
                    :color="
                      get_compra.tipo === 'balcao' ? $theme.primary : '#fff'
                    "
                    :dark="get_compra.tipo === 'balcao' ? true : false"
                    class="mx-1 gre elevation-0"
                    :class="{
                      'grey--text': get_compra.tipo !== 'balcao',
                      'fonte-bold white--text': get_compra.tipo === 'balcao'
                    }"
                  >
                    <span>Balcão</span>
                  </v-btn>
                  <v-btn
                    rounded
                    x-small
                    @click.prevent="setTypeBuy('mesa')"
                    :color="
                      get_compra.tipo === 'mesa' ? $theme.primary : '#fff'
                    "
                    :dark="get_compra.tipo === 'mesa' ? true : false"
                    class="mx-1 gre elevation-0"
                    :class="{
                      'grey--text': get_compra.tipo !== 'mesa',
                      'fonte-bold white--text': get_compra.tipo === 'mesa'
                    }"
                  >
                    Mesa {{ get_compra.mesa ? get_compra.mesa.number : "" }}
                  </v-btn>
                  <v-btn
                    rounded
                    x-small
                    @click.prevent="() => $refs.modalChangeTable.open('n')"
                    v-if="get_compra.tipo === 'mesa' && get_compra._id"
                    :color="
                      get_compra.tipo === 'mesa' ? $theme.primary : '#fff'
                    "
                    :dark="get_compra.tipo === 'mesa' ? true : false"
                    class="mx-1 gre elevation-0"
                    text
                    >Trocar mesa
                  </v-btn>
                  <v-btn
                    rounded
                    x-small
                    @click.prevent="setTypeBuy('delivery')"
                    :color="
                      get_compra.tipo === 'delivery' ? $theme.primary : '#fff'
                    "
                    :dark="get_compra.tipo === 'delivery' ? true : false"
                    class="mx-1 fonte elevation-0"
                    :class="{
                      'grey--text': get_compra.tipo !== 'delivery',
                      'fonte-bold white--text': get_compra.tipo === 'delivery'
                    }"
                    >Delivery</v-btn
                  >
                  <v-btn
                    rounded
                    x-small
                    @click.prevent="setTypeBuy('retirada')"
                    :color="
                      get_compra.tipo === 'retirada' ? $theme.primary : '#fff'
                    "
                    :dark="get_compra.tipo === 'retirada' ? true : false"
                    class="mx-1 fonte elevation-0"
                    :class="{
                      'grey--text': get_compra.tipo !== 'retirada',
                      'fonte-bold white--text': get_compra.tipo === 'retirada'
                    }"
                    >Retirada</v-btn
                  >
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="expande-horizontal">
                  <v-text-field
                    @keyup.enter="attachOpenModalAddProduct"
                    v-model="inputSearch"
                    :label="
                      $vuetify.breakpoint.lgAndUp
                        ? '[esc] Pesquise'
                        : 'Pesquise'
                    "
                    placeholder="Nome ou código"
                    ref="searchField"
                    prepend-icon="mdi-barcode"
                    hide-details
                    class="mx-3 fonte"
                    dense
                    color="deep-purple"
                  ></v-text-field>
                  <v-autocomplete
                    class="fonte"
                    label="Cliente"
                    :items="get_clientes.docs"
                    item-text="nome"
                    return-object
                    hide-details
                    dense
                    append-icon="mdi-plus"
                    @click:append="abre_modal_view_cliente"
                    color="deep-purple"
                    v-model="get_compra.cliente"
                  ></v-autocomplete>
                  <v-btn
                    icon
                    class="pt-0"
                    :color="$theme.primary"
                    @click.prevent="limparComanda({ confirmation: false })"
                  >
                    <v-icon color="red lighten-2">mdi-broom</v-icon>
                  </v-btn>
                </div>
              </v-flex>
              <div class="wrap expande-horizontal pb-0">
                <span
                  style="cursor: pointer;"
                  @click="setTypeBuy('delivery')"
                  class="fonte fonteMini font-weight-bold px-3"
                >
                  {{ endereco() }}</span
                >
              </div>

              <!-- Categorias de Produtos -->
              <v-flex v-if="inputSearch === ''" class="pt-0 pb-3" xs12>
                <v-flex xs12 class="pa-4 pt-2 pb-2">
                  <span class="fonte fonte-bold mt-3 mb-3">Categorias</span>
                </v-flex>
                <v-slide-group class="ml-2" show-arrows>
                  <v-slide-item>
                    <v-btn
                      class="ml-1 mr-2 fonte text-capitalize fonte-bold"
                      :input-value="allCategories"
                      active-class="purple white--text"
                      depressed
                      @click.prevent="setCategory(false)"
                    >
                      Todos
                    </v-btn>
                  </v-slide-item>
                  <v-slide-item
                    v-for="categoria in get_produtos.docs"
                    v-show="
                      categoria.preco_multiplo &&
                        categoria.preco_multiplo.filter(
                          preco => preco.disponivel
                        ).length
                    "
                    :key="categoria._id"
                  >
                    <v-btn
                      class="mx-2 fonte text-capitalize fonte-bold"
                      :input-value="categoria._id === categoria_escolhida._id"
                      active-class="purple white--text"
                      depressed
                      @click.prevent="setCategory(categoria)"
                    >
                      {{ categoria.nome }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-flex>

              <v-flex class="pt-0" xs12>
                <div class="expande-horizontal wrap">
                  <v-flex xs12 class="pa-5 pb-2 pt-0">
                    <span
                      v-if="inputSearch !== ''"
                      class="fonte fonte-bold grey--text mt-3 mb-3"
                    >
                      {{ productSearchList.length }} encontrados</span
                    >
                  </v-flex>
                  <div class="px-3 expande-horizontal wrap">
                    <v-flex
                      class="pa-1"
                      :key="produto.nome"
                      v-show="produto.disponivel && inputSearch === ''"
                      v-for="produto in categoria_escolhida.preco_multiplo"
                      xs3
                    >
                      <v-card
                        color="#e5e5e5"
                        @click.prevent="openModalAddProduct(produto)"
                        class="mx-auto pt-2 animate__animated animate__flipInY"
                        max-width="344"
                        flat
                      >
                        <v-img
                          height="60"
                          style="border-radius: 6px !important;"
                          :src="produto.image || 'img/logo_mini.png'"
                        ></v-img>

                        <h4 class="text-center black--text fonte fonte-bold">
                          {{ produto.nome }}
                        </h4>
                        <h4
                          class="text-center green--text fonte fonte-bold"
                          style="color: #2cc4ea"
                        >
                          <!-- {{ produto.code }} -->
                          {{ $helper.formataSaldo(produto.preco_com_markup) }}
                        </h4>
                      </v-card>
                    </v-flex>
                    <v-flex
                      class="pa-1"
                      :key="`product-search-${produto.nome}`"
                      v-show="inputSearch !== ''"
                      v-for="produto in productSearchList"
                      xs3
                    >
                      <v-card
                        @click.prevent="openModalAddProduct(produto)"
                        :color="$theme.secondary"
                        class="mx-auto animate__animated animate__flipInY"
                        max-width="344"
                        flat
                      >
                        <v-img
                          height="60"
                          v-if="produto.image"
                          :src="produto.image"
                        ></v-img>

                        <h6 class="text-center white--text fonte fonte-bold">
                          {{ produto.nome }}
                        </h6>
                        <h4
                          class="text-center fonte fonte-bold"
                          style="color: #fff"
                        >
                          {{ $helper.formataSaldo(produto.preco_com_markup) }}
                        </h4>
                      </v-card>
                    </v-flex>
                  </div>
                  <div
                    :class="{
                      'max-height-list-products': $vuetify.breakpoint.lgAndUp,
                      'max-height-list-products-mobile':
                        $vuetify.breakpoint.smAndDown
                    }"
                    class="expande-horizontal wrap"
                    style="overflow: auto;"
                  >
                    <v-flex
                      :key="`category-${categoria.nome}-${i}`"
                      v-show="allCategories && inputSearch === ''"
                      v-for="(categoria, i) in get_produtos.docs"
                      xs12
                      class="px-3"
                    >
                      <v-list class="pa-0 ma-0">
                        <v-list-item
                          @click.prevent="openModalAddProduct(produto)"
                          v-show="produto.disponivel"
                          class="fonte px-0 mx-0 pr-3 pl-2 item-list-product animate__animated animate__fadeInUp"
                          :key="`product-multiple-${produto.nome}`"
                          v-for="produto in categoria.preco_multiplo"
                        >
                          <v-avatar class="mr-3">
                            <v-img
                              height="60"
                              v-if="produto.image"
                              :src="produto.image"
                            ></v-img>
                            <img
                              contain
                              height="40"
                              v-else
                              src="img/logo_mini.png"
                            />
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="black--text font-weight-bold"
                            >
                              {{ produto.nome }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="black--text fonteMini">
                              <v-icon>mdi-barcode</v-icon> {{ produto.code }}
                              <!-- {{
                                $helper.formataSaldo(produto.preco_com_markup)
                              }} -->
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn text>
                              {{
                                $helper.formataSaldo(produto.preco_com_markup)
                              }}
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                  </div>
                </div>
              </v-flex>
            </div>
          </v-flex>
          <v-flex
            class="pa-1 pb-0 pr-0 pt-0"
            v-if="$vuetify.breakpoint.smAndUp"
            xs12
            md6
          >
            <v-card class="elevation-0 cool-shaddow" tile>
              <v-flex xs12>
                <div
                  class="expande-horizontal wrap"
                  style="background: #F0F0F0; min-height: 91vh;"
                >
                  <!-- produtos adicionados -->
                  <v-flex xs12>
                    <div class="expande-horizontal mt-0 pb-0">
                      <v-flex v-if="get_compra && get_compra.produtos" xs12>
                        <v-simple-table
                          dark
                          style="witdh: 100%; height: 73vh; border-bottom: 1px solid #e0e0e0; overflow: auto; background: #f0f0f0;"
                          dense
                          class="scroller-items"
                        >
                          <template v-slot:default>
                            <thead style="background: #EBEBEB; height: 40px;">
                              <tr
                                style="background: #EBEBEB; border: 1px solid #f0f0f0;"
                              >
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Código
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Item
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Quant
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Preço
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686;"
                                >
                                  Subtotal
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                style="border-top: 1px solid #D2D4CF;"
                                v-for="(item, i) in get_compra.produtos"
                              >
                                <tr
                                  class="mt-3"
                                  :class="{
                                    'product-done': item.is_done
                                  }"
                                  @click="openModalAddProduct(item, `${i}`)"
                                  style="background: #f0f0f0; cursor: pointer; border: 1px solid #f0f0f0;"
                                  :key="`product-in-list-${i}`"
                                >
                                  <td
                                    @click.prevent="abre_modal_view_caixa(item)"
                                    style="color: #878686; width: 10px; border-top: 1px dashed #D2D4CF;"
                                    class="fonte fonte-bold"
                                  >
                                    # {{ item.code ? item.code : "N/A" }}
                                  </td>
                                  <td
                                    class="fonte fonte-bold"
                                    style="color: #494949; border-top: 1px dashed #D2D4CF;"
                                    @click.prevent="abre_modal_view_caixa(item)"
                                  >
                                    {{ item.nome }}
                                  </td>
                                  <td
                                    class="fonte fonte-bold"
                                    style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                    @click.prevent="abre_modal_view_caixa(item)"
                                  >
                                    {{ item.quantity }}x
                                  </td>
                                  <td
                                    class="fonte green--text fonte-bold"
                                    style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                    @click.prevent="abre_modal_view_caixa(item)"
                                  >
                                    {{
                                      $helper.formataSaldo(
                                        item.preco_com_markup
                                      )
                                    }}
                                  </td>
                                  <td
                                    class="fonte green--text fonte-bold"
                                    style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                    @click.prevent="abre_modal_view_caixa(item)"
                                  >
                                    {{
                                      $helper.formataSaldo(item.seller_price)
                                    }}
                                  </td>
                                </tr>
                                <template
                                  v-for="(adicional, i) in item.adicionais"
                                >
                                  <tr
                                    :class="{
                                      'product-done': item.is_done
                                    }"
                                    style="background: #f0f0f0; border: none;"
                                    v-for="(item, index) in adicional.itens"
                                    :key="(index + 1) / 4"
                                    v-show="
                                      item.quantidade_adicionada &&
                                        item.quantidade_adicionada >= 1
                                    "
                                  >
                                    <td
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                      style="color: #878686; width: 10px"
                                      class="fonte fonte-micro"
                                    >
                                      <!-- # {{ item.code ? item.code : "N/A" }} -->
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686;"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{ item.descricao_personalizada }}
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{ item.quantidade_adicionada }}x
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{ $helper.formataSaldo(item.preco) }}
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{
                                        $helper.formataSaldo(
                                          item.quantidade_adicionada *
                                            parseFloat(item.preco)
                                        )
                                      }}
                                    </td>
                                  </tr>
                                </template>
                                <tr
                                  light
                                  v-show="item.obs"
                                  style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                                  :key="(i + 1) / 6"
                                  xs12
                                >
                                  <td
                                    class="fonte fonte-micro"
                                    style="color: #878686"
                                  >
                                    Obs:
                                  </td>
                                  <td
                                    class="fonte fonte-micro"
                                    style="color: #878686"
                                  >
                                    {{ item.obs }}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                      <div
                        v-else
                        style="height: 78vh;"
                        class="expande-horizontal centraliza pb-6 column"
                      >
                        <v-img
                          width="60"
                          contain
                          height="40"
                          src="img/logo_mini.svg"
                          class="mb-3"
                        />

                        <!-- <v-icon color="grey" size="64">mdi-cart-check</v-icon> -->
                        <!-- <h4 class="fonte grey--text">
                          Caixa livre
                        </h4> -->
                      </div>
                    </div>
                  </v-flex>

                  <div
                    style="background: #e5e5e5;"
                    class="pt-3 pb-2 expande-horizontal wrap"
                  >
                    <v-flex xs12>
                      <div class="expande-horizontal">
                        <v-flex class="px-3" xs6>
                          <v-btn
                            outlined
                            bigger
                            @click.prevent="$refs.modalAcrescimo.open()"
                            color="grey"
                            class="fonte text-capitalize"
                            block
                            text
                          >
                            <v-icon :color="$theme.primary"
                              >mdi-plus-circle-outline</v-icon
                            >
                            <h4>
                              Acréscimo
                              {{
                                $helper.formataSaldo(get_compra.acrescimo || 0)
                              }}
                            </h4>
                          </v-btn>
                        </v-flex>
                        <v-flex class="px-3" xs6>
                          <v-btn
                            outlined
                            bigger
                            @click.prevent="$refs.modalDesconto.open()"
                            color="grey"
                            class="fonte text-capitalize"
                            block
                            text
                          >
                            <v-icon :color="$theme.primary"
                              >mdi-minus-circle-outline</v-icon
                            >
                            <h4>
                              Desconto
                              {{
                                $helper.formataSaldo(get_compra.desconto || 0)
                              }}
                            </h4>
                          </v-btn>
                        </v-flex>
                      </div>
                      <div class="expande-horizontal mt-3 wrap">
                        <v-flex class="px-6" xs12 md6>
                          <span class="fonte fonte-bold grey--text mr-3"
                            >SUBTOTAL:</span
                          >
                          <span class="green--text fonte fonte-bold">{{
                            $helper.formataSaldo(get_compra.subtotal || 0)
                          }}</span>
                        </v-flex>
                        <v-flex class="px-6" xs12 md6>
                          <span class="fonte fonte-bold grey--text mr-3"
                            >TOTAL:</span
                          >
                          <span class="green--text fonte fonte-bold">{{
                            $helper.formataSaldo(get_compra.preco_total || 0)
                          }}</span>
                        </v-flex>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="expande-horizontal fonte px-3 pr-2">
                        <v-flex class="pr-1" xs6>
                          <v-btn
                            v-show="
                              get_compra.status_do_pagamento !== 'pago' &&
                                !get_compra._id
                            "
                            v-if="get_compra.produtos.length > 0"
                            class="text-capitalize font-weight-bold elevation-0"
                            @click.prevent="initSendToManager"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Iniciar pedido
                            <v-spacer></v-spacer>
                            <v-icon class="ml-3">mdi-check</v-icon>
                          </v-btn>
                          <!-- <v-btn
                            v-show="get_compra._id"
                            v-if="get_compra.produtos.length > 0"
                            class="text-capitalize font-weight-bold elevation-0"
                            @click.prevent="initSendToManager('cozinha')"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Enviar para cozinha
                            <v-spacer></v-spacer>
                            <v-icon class="ml-3">mdi-check</v-icon>
                          </v-btn> -->
                          <v-btn
                            v-show="get_compra._id"
                            v-if="get_compra.produtos.length > 0"
                            class="text-capitalize font-weight-bold elevation-0"
                            @click.prevent="initSendToManager('quiet')"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Atualizar
                            <v-spacer></v-spacer>
                            <v-icon class="ml-3">mdi-check</v-icon>
                          </v-btn>
                        </v-flex>
                        <v-flex xs6>
                          <!-- <v-btn
                            v-if="
                              get_compra.status_do_pagamento !== 'pago' &&
                                !get_compra._id
                            "
                            class="text-capitalize font-weight-bold elevation-0"
                            @click.prevent="initPayment"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Pagar.
                            <v-spacer></v-spacer>
                            <v-icon class="ml-10">mdi-check-all</v-icon>
                          </v-btn> -->
                          <!-- <v-btn
                            v-if="
                              get_compra.status_do_pagamento !== 'pago' &&
                                get_compra._id
                            "
                            class="text-capitalize font-weight-bold elevation-0"
                            @click.prevent="initPaymentId"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Pagar
                            <v-spacer></v-spacer>
                            <v-icon class="ml-10">mdi-check-all</v-icon>
                          </v-btn> -->
                          <v-btn
                            v-if="get_compra.status_do_pagamento === 'pago'"
                            class="text-capitalize font-weight-bold elevation-0"
                            @click.prevent="updateStatusToFinish"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Finalizar pedido
                            <v-spacer></v-spacer>
                            <v-icon class="ml-10">mdi-check-all</v-icon>
                          </v-btn>
                        </v-flex>
                      </div>
                    </v-flex>
                  </div>
                </div>
              </v-flex>
            </v-card>
          </v-flex>
        </v-card>
      </v-flex>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="receipt"
      :manual-pagination="true"
      pdf-format="legal"
      :pdf-quality="10"
      pdf-content-width="80"
      pdf-orientation="portrait"
      @progress="() => {}"
      @hasStartedGeneration="() => {}"
      @hasDownloaded="() => {}"
      ref="html2Pdf"
    >
      <div slot="pdf-content">
        <section class="pdf-item fonte">
          <div
            class="expande-horizontal column wrap"
            style="max-width: 380px;padding: 6px;"
          >
            <div class="fonte expande-horizontal centraliza logo-receipt">
              <img
                v-if="
                  getLoggedUser.tenant[0].photos &&
                    getLoggedUser.tenant[0].photos.length
                "
                :src="getLoggedUser.tenant[0].photos[0]"
                alt="logo"
                style="height: 100px;"
              />
              <img
                v-else
                src="img/logo_minni.svg"
                style="height: 60px"
                alt=""
              />
            </div>
            <div
              class="expande-horizontal column centraliza address-establishment-receipt"
            >
              <span class="fonte-micro">
                {{ getLoggedUser.tenant[0].defaultAddress }}
              </span>
              <!-- header text -->
              <span v-if="receipt.show_header_text.active">
                {{ receipt.show_header_text.text }}
              </span>
              <strong class="mt-2 mb-2 fonte-mini">
                *** NÃO É DOCUMENTO FISCAL ***
              </strong>
              <span class="fonte-micro">
                Realizado
                {{ moment(lastSell.createdAt).format("DD/MM/YYYY HH:mm") }}
              </span>
            </div>
            <div class="expande-horizontal centraliza id-receipt">
              <h3>ID Pedido: #{{ lastSell.id_transacao }}</h3>
            </div>
            <div
              class="expande-horizontal divider"
              style="height: 1px; border-bottom: 1px #333 dashed;"
            ></div>
            <div
              v-if="receipt.show_client_details && lastSell.compra.cliente"
              class="expande-horizontal column"
            >
              <div class="expande-horizontal" v-if="lastSell.compra.cliente">
                <strong style="margin-right: 6px;">
                  Cliente:
                </strong>
                {{ lastSell.compra.cliente.name }}
              </div>
              <div class="expande-horizontal" v-if="lastSell.compra.cliente">
                <strong style="margin-right: 6px;">
                  Contato:
                </strong>
                {{ lastSell.compra.cliente.telefone }}
              </div>
              <div class="expande-horizontal" v-if="lastSell.compra.cliente">
                <strong style="margin-right: 6px;">
                  Whatsapp:
                </strong>
                {{ lastSell.compra.cliente.whatsapp }}
              </div>
            </div>
            <div class="expande-horizontal column">
              <div class="expande-horizontal">
                <strong style="margin-right: 6px;">
                  Atendimento:
                </strong>
                {{ lastSell.compra.tipo }}
              </div>
              <div class="expande-horizontal">
                <strong style="margin-right: 6px;">
                  Saída:
                </strong>
                {{
                  lastSell.compra.tipo === "delivery"
                    ? "Entrega por delivery"
                    : "Retirada no local"
                }}
              </div>
              <div v-if="lastSell.compra.address" class="expande-horizontal">
                <strong style="margin-right: 6px;">
                  Endereço:
                </strong>
                {{ lastSell.compra.address?.street }} -
                {{ lastSell.compra.address?.city }},
                {{ lastSell.compra.address?.bairro }}, número
                {{ lastSell.compra.address?.number }}.
              </div>
              <div
                v-if="lastSell.compra.address && lastSell.compra.address.ref"
                class="expande-horizontal"
              >
                <strong style="margin-right: 6px;">
                  Referência:
                </strong>
                {{ lastSell.compra.address.ref }}
              </div>
            </div>
            <div
              class="expande-horizontal divider"
              style="height: 1px; border-bottom: 1px #333 dashed;"
            ></div>
            <div>
              <div
                v-for="(item, i) in lastSell.compra.produtos"
                :key="`product-print-${i}`"
                class="pb-6"
              >
                {{ item.codigo ? `#${item.codigo}` : "" }} {{ item.quantity }}x
                {{ item.nome }} = {{ $helper.formataSaldo(item.seller_price) }}
                <!-- <v-flex xs12>
                    <v-simple-table
                      dark
                      style="witdh: 100%; height: 200px; overflow: auto; background: #f0f0f0;"
                      dense
                    >
                      <template v-slot:default>
                        <thead style="background: #EBEBEB; height: 40px;">
                          <tr
                            style="background: #EBEBEB; border: 1px solid #f0f0f0;"
                          >
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Código
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Item
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Quant
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Preço
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686;"
                            >
                              Subtotal
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            style="border-top: 1px solid #D2D4CF;"
                            v-for="(item, i) in lastSell.compra.produtos"
                          >
                            <tr
                              class="mt-3"
                              @click="openModalAddProduct(item, `${i}`)"
                              style="background: #f0f0f0; cursor: pointer; border: 1px solid #f0f0f0;"
                              :key="`product-print-in-list-${i}`"
                            >
                              <td
                                @click.prevent="abre_modal_view_caixa(item)"
                                style="color: #878686; width: 10px; border-top: 1px dashed #D2D4CF;"
                                class="fonte fonte-bold"
                              >
                                # {{ item.code ? item.code : "N/A" }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #494949; border-top: 1px dashed #D2D4CF;"
                              >
                                {{ item.nome }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                              >
                                {{ item.quantity }}x
                              </td>
                              <td
                                class="fonte green--text fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                              >
                                {{
                                  $helper.formataSaldo(item.preco_com_markup)
                                }}
                              </td>
                              <td
                                class="fonte green--text fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                              >
                                
                              </td>
                            </tr>
                            <template v-for="(adicional, i) in item.adicionais">
                              <tr
                                style="background: #f0f0f0; border: none;"
                                v-for="(item, index) in adicional.itens"
                                :key="(index + 1) / 4"
                                v-show="
                                  item.quantidade_adicionada &&
                                    item.quantidade_adicionada >= 1
                                "
                              >
                                <td
                                  @click.prevent="abre_modal_view_caixa(item)"
                                  style="color: #878686; width: 10px"
                                  class="fonte fonte-micro"
                                >
                                </td>
                                <td
                                  class="fonte fonte-micro"
                                  style="color: #878686;"
                                  @click.prevent="abre_modal_view_caixa(item)"
                                >
                                  {{ item.descricao_personalizada }}
                                </td>
                                <td
                                  class="fonte fonte-micro"
                                  style="color: #878686"
                                  @click.prevent="abre_modal_view_caixa(item)"
                                >
                                  {{ item.quantidade_adicionada }}x
                                </td>
                                <td
                                  class="fonte fonte-micro"
                                  style="color: #878686"
                                  @click.prevent="abre_modal_view_caixa(item)"
                                >
                                  {{ $helper.formataSaldo(item.preco) }}
                                </td>
                                <td
                                  class="fonte fonte-micro"
                                  style="color: #878686"
                                  @click.prevent="abre_modal_view_caixa(item)"
                                >
                                  {{
                                    $helper.formataSaldo(
                                      item.quantidade_adicionada *
                                        parseFloat(item.preco)
                                    )
                                  }}
                                </td>
                              </tr>
                            </template>
                            <tr
                              light
                              v-show="item.obs"
                              style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                              :key="(i + 1) / 6"
                              xs12
                            >
                              <td
                                class="fonte fonte-micro"
                                style="color: #878686"
                              >
                                Obs:
                              </td>
                              <td
                                class="fonte fonte-micro"
                                style="color: #878686"
                              >
                                {{ item.obs }}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex> -->
              </div>
            </div>
            <div
              class="expande-horizontal divider"
              style="height: 1px; border-bottom: 1px #333 dashed;"
            ></div>
            <div class="expande-horizontal">
              <span>(+) Subtotal</span>
              <v-spacer></v-spacer>
              <span>
                {{ $helper.formataSaldo(lastSell.compra.subtotal) }}
              </span>
            </div>

            <div class="expande-horizontal">
              <span>(+) Taxa de entrega</span>
              <v-spacer></v-spacer>
              <span>
                {{ $helper.formataSaldo(lastSell.compra.taxa_entrega || 0) }}
              </span>
            </div>

            <div class="expande-horizontal">
              <span>(+) Adicional</span>
              <v-spacer></v-spacer>
              <span>
                {{ $helper.formataSaldo(lastSell.compra.acrescimo || 0) }}
              </span>
            </div>

            <div class="expande-horizontal">
              <span>(-) Desconto</span>
              <v-spacer></v-spacer>
              <span>
                {{ $helper.formataSaldo(lastSell.compra.desconto || 0) }}
              </span>
            </div>

            <div
              style="font-weight: bold; margin-top: 24px;"
              class="expande-horizontal mt-6"
            >
              <span>(=) Total do pedido: </span>
              <v-spacer></v-spacer>
              <span>
                {{ $helper.formataSaldo(lastSell.compra.preco_total) }}
              </span>
            </div>
            <div
              class="expande-horizontal divider"
              style="height: 1px; border-bottom: 1px #333 dashed;"
            ></div>
            <div
              style="margin-bottom: 12px;"
              class="expande-horizontal centraliza"
            >
              <span class="text-center" v-if="receipt.show_footer_text.active">
                {{ receipt.show_footer_text.text }}
              </span>
            </div>
            <!-- <div
              v-if="pedidoRealizadoPeloMenuRocket"
              class="expande-horizontal centraliza column"
            >
              <span class="fonte text-center" style="font-size: 9pt"
                >Pedido realizado pelo</span
              >
              <img style="height: 40px;" src="img/logo_menu_black.png" />
            </div>
            <div class="expande-horizontal centraliza">
              <img style="height: 20px;" src="img/logo_black.png" />
            </div> -->
            <!-- <div style="font-size: 9pt" class="expande-horizontal centraliza">
              market.scnrevolution.com
            </div> -->
          </div>
        </section>
      </div>
    </vue-html2pdf>
    <ModalViewAbertura />
    <ModalViewFechamento />
    <ModalAcrescimo ref="modalAcrescimo" />
    <ModalDesconto ref="modalDesconto" />
    <ModalFechamento
      :payment_sys_config="payment_sys_config"
      :sendToPreparing="finishSell"
      :limparComanda="limparComanda"
      ref="modalFechamento"
    />
    <ModalFechamentoId
      :payment_sys_config="payment_sys_config"
      :updatePayment="updatePayment"
      :limparComanda="limparComanda"
      :updateStatusToFinish="updateStatusToFinish"
      ref="modalFechamentoId"
    />
    <ModalSendToManager
      :payment_sys_config="payment_sys_config"
      :initSale="finishSell"
      ref="modalSendToManager"
    />
    <ModalAddProduct
      :addProductToCart="addProductToCart"
      :removeProductOfCart="removeProductOfCart"
      ref="modalAddProduct"
    />
    <ModalChangeTable
      :mesas_disponiveis="mesas_disponiveis"
      :selecionarMesa="trocarMesa"
      ref="modalChangeTable"
    />
    <ModalViewTable
      :mesas_disponiveis="mesas_disponiveis"
      :selecionarMesa="selecionarMesa"
      ref="modalViewTable"
    />
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      style="position: fixed; bottom: 0px; left: 0; right: 0; z-index: 5;"
      class="expande-horizontal centraliza"
    >
      <v-flex class="px-0" xs12 md8>
        <v-card
          :color="$theme.primary"
          class="fonte expande-horizontal elevation-0"
        >
          <v-expansion-panels
            class="elevation-0"
            flat
            style="padding: 0px; background: red;"
            :color="$theme.primary"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                class="fonte"
                expand-icon="mdi-menu-down"
                ref="expansionPanelHeader"
              >
                <v-list color="transparent" class="pa-0 ma-0" dense>
                  <v-list-item
                    v-if="saleStage === 0"
                    class="pa-0 ma-0"
                    style="background: #673AB7; border-radius: 6px;"
                  >
                    <v-icon class="mx-2" color="#f2f2f2"
                      >mdi-basket-outline</v-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold white--text ">
                        {{ get_compra.produtos.length }} produtos, total de
                        {{ $helper.formataSaldo(get_compra.preco_total || 0) }}
                      </v-list-item-title>
                      <v-list-item-subtitle class=" white--text fonte-micro">
                        Visualize os produtos
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="saleStage === 1" class="pa-0 ma-0">
                    <v-avatar class="pa-0 ma-0 mr-2" color="red">
                      <v-icon color="#f2f2f2">mdi-map-marker-outline</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: red;"
                        class="font-weight-bold"
                      >
                        Endereço de entrega
                      </v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro">
                        Escolha ou cadastre um abaixo
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="saleStage === 2" class="pa-0 ma-0">
                    <v-avatar class="pa-0 ma-0 mr-2" color="red">
                      <v-icon size="36" color="#f2f2f2"
                        >mdi-information-variant</v-icon
                      >
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: red;"
                        class="font-weight-bold"
                      >
                        CONFIRME OS DETALHES
                      </v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro">
                        confira seu pedido e finalize
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex class="pt-0" xs12>
                  <v-window :touchless="true" v-model="saleStage">
                    <v-window-item :key="0">
                      <div
                        v-if="get_compra.produtos.length"
                        style="height: 76vh;"
                        class="expande-horizontal pa-3 pt-0 pr-0 pl-0 column"
                      >
                        <v-list
                          style="max-height: 800px; overflow: auto;"
                          class="pa-0 ma-0 pt-1 pb-1 fonte"
                          dense
                        >
                          <template v-for="(produto, i) in get_compra.produtos">
                            <v-list-item
                              @click="openModalAddProduct(produto, `${i}`)"
                              class="pl-0 pr-0 ma-0 mb-3"
                              :key="`product-added-${i}`"
                              style="border-bottom: 1px solid #e5e5e5;"
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  :style="`font-size: 12pt; color: black;`"
                                  class="font-weight-bold"
                                >
                                  {{ produto.quantity }}x {{ produto.nome }}
                                </v-list-item-title>
                                <template v-for="items in produto.adicionais">
                                  <v-list-item-subtitle
                                    v-show="item.quantidade_adicionada"
                                    class="ml-0 mt-1"
                                    :key="`${i}-${_i}-adicional`"
                                    v-for="(item, _i) in items.itens"
                                  >
                                    {{ item.quantidade_adicionada }}
                                    {{ item.descricao_personalizada }}
                                    <!-- <span class="green--text"> + {{ item.preco }} </span> -->
                                  </v-list-item-subtitle>
                                </template>
                                <v-list-item-subtitle
                                  v-if="produto.obs"
                                  class="ml-0 mb-1 fonte-micro"
                                >
                                  <span class="font-weight-bold">Obs:</span>
                                  {{ produto.obs }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="fonteMini grey--text ml-0"
                                >
                                  {{
                                    $helper.formataSaldo(
                                      produto.seller_price || 0
                                    )
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <!-- <v-list-item-action>
                                <img
                                  class="img-icon"
                                  v-if="produto.image"
                                  :src="produto.image"
                                />
                              </v-list-item-action> -->
                            </v-list-item>
                          </template>
                        </v-list>
                        <v-spacer></v-spacer>
                        <div class="pt-3 expande-horizontal column">
                          <h3 class="fonteMini pl-0 pa-3 grey--text">
                            opções:
                          </h3>
                          <v-btn
                            v-show="
                              get_compra.status_do_pagamento !== 'pago' &&
                                !get_compra._id
                            "
                            v-if="get_compra.produtos.length > 0"
                            class="text-capitalize font-weight-bold elevation-0"
                            @click.prevent="initSendToManager"
                            color="deep-purple"
                            dark
                            block
                            large
                          >
                            Iniciar pedido
                            <v-spacer></v-spacer>
                            <v-icon class="ml-3">mdi-check</v-icon>
                          </v-btn>
                          <v-btn
                            v-show="get_compra._id"
                            v-if="get_compra.produtos.length > 0"
                            class="text-capitalize font-weight-bold elevation-0"
                            @click.prevent="initSendToManager('cozinha')"
                            color="deep-purple"
                            dark
                            block
                            large
                          >
                            Enviar para o gestor
                            <v-spacer></v-spacer>
                            <v-icon class="ml-3">mdi-check</v-icon>
                          </v-btn>
                          <v-btn
                            v-show="get_compra._id"
                            v-if="get_compra.produtos.length > 0"
                            class="text-capitalize mt-2 font-weight-bold elevation-0"
                            @click.prevent="initSendToManager"
                            :color="$theme.secondary"
                            dark
                            block
                            large
                          >
                            Atualizar somente
                            <v-spacer></v-spacer>
                            <v-icon class="ml-3">mdi-check</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="
                              get_compra.status_do_pagamento !== 'pago' &&
                                !get_compra._id
                            "
                            class="text-capitalize font-weight-bold mt-2 elevation-0"
                            @click.prevent="initPayment"
                            :color="$theme.secondary"
                            dark
                            block
                            large
                          >
                            Pagar.
                            <v-spacer></v-spacer>
                            <v-icon class="ml-10">mdi-check-all</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="
                              get_compra.status_do_pagamento !== 'pago' &&
                                get_compra._id
                            "
                            class="text-capitalize mt-2 font-weight-bold elevation-0"
                            @click.prevent="initPaymentId"
                            :color="$theme.secondary"
                            dark
                            block
                            large
                          >
                            Pagar
                            <v-spacer></v-spacer>
                            <v-icon class="ml-10">mdi-check-all</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="get_compra.status_do_pagamento === 'pago'"
                            class="text-capitalize mt-2 font-weight-bold elevation-0"
                            @click.prevent="updateStatusToFinish"
                            :color="$theme.secondary"
                            dark
                            block
                            large
                          >
                            Finalizar pedido
                            <v-spacer></v-spacer>
                            <v-icon class="ml-10">mdi-check-all</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div
                        v-else
                        style="height: 62vh;"
                        class="expande-horizontal centraliza pa-3 pr-0 pl-0 column"
                      >
                        <v-flex xs10 md4>
                          <LottieAnimation
                            class="pa-6"
                            ref="anim2"
                            :animationData="
                              require('@/apps/shared/assets/empty_sales.json')
                            "
                          />
                        </v-flex>
                        <v-flex xs10 md10>
                          <div class="expande-horizontal fonte grey--text">
                            A cesta está vazia
                          </div>
                        </v-flex>
                        <v-flex xs10 md10>
                          <div
                            class="expande-horizontal centraliza fonte grey--text"
                          >
                            <v-btn
                              @click="closeExpansionPanelCheckout"
                              class="font-weight-bold"
                              color="deep-purple"
                              rounded
                              dark
                              >Adicionar itens
                              <v-icon class="ml-2"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-flex>
                      </div>
                    </v-window-item>
                    <v-window-item :key="1">
                      <div class="expande-horizontal pa-0 ma-0">
                        <ChangeDelivery
                          :ok="address => setAddress(address)"
                          :cancel="() => (saleStage = 0)"
                          :stepBack="() => (saleStage = 0)"
                        />
                      </div>
                    </v-window-item>
                    <v-window-item :key="2">
                      <div
                        class="fonte expande-horizontal column"
                        style="height: 61vh;"
                      >
                        <v-list three-line class="pa-0 ma-0" dense>
                          <v-list-item
                            @click="saleStage = 0"
                            v-if="get_compra.address"
                            class="mb-1 pl-0"
                          >
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-basket-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Revisar</v-list-item-title
                              >
                              <v-list-item-subtitle
                                >Clique aqui caso queira remover ou adicionar
                                produtos</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            @click="saleStage = 1"
                            v-if="get_compra.address"
                            class="mb-1 pl-0"
                          >
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-map-marker-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Endereço</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                get_compra.address.formatted_address
                              }}</v-list-item-subtitle>
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="mb-1 pl-0">
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-moped-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Taxa de entrega</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                R$ 0,00</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="mb-1 pl-0">
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-clock-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Tempo de entrega</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                40 - 60 minutos</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="mb-1 pl-0">
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-clock-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Forma de pagamento</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                ??????</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-spacer></v-spacer>
                          <!-- <v-list-item class="">
                            <v-btn
                              small
                              text
                              :color="$theme.primary"
                              dark
                              @click="saleStage = 0"
                              >
                                <v-icon size="18" class="mr-3">mdi-chevron-left</v-icon>
                              Revisar pedido</v-btn
                            >
                          </v-list-item> -->
                        </v-list>
                        <v-spacer></v-spacer>
                        <!-- <v-btn
                          :color="$theme.primary"
                          dark
                          rounded
                          class="fonte elevation-0 font-weight-bold"
                          @click="realizarPedido"
                          >Realizar pedido</v-btn
                        > -->
                      </div>
                    </v-window-item>
                  </v-window>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-flex>
    </div>
    <div
      v-show="inPrinting"
      class="expande-horizontal"
      style="z-index: 10000; position: absolute; top: 2000px;"
    >
      <div
        id="print-html"
        style="width: 100%;padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
      >
        <div
          style="display: flex; width: 100%; justify-content: center;"
          class="fonte expande-horizontal centraliza logo-receipt"
        >
          <img
            v-if="
              getLoggedUser.tenant[0].photos &&
                getLoggedUser.tenant[0].photos.length
            "
            :src="getLoggedUser.tenant[0].photos[0]"
            alt="logo"
            style="height: 80px;"
          />
          <img contain v-else height="40" sty src="img/logo_mini.svg" />
        </div>
        <div
          style="display: flex; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <!-- <span class="fonte-micro">
            {{ getLoggedUser.tenant[0].defaultAddress }}
          </span> -->
          <!-- header text -->
          <div style="display: flex; width: 100%; justify-content: center;">
            <span v-if="receipt.show_header_text.active">
              {{ receipt.show_header_text.text }}
            </span>
          </div>
          <div style="display: flex; width: 100%; justify-content: center;">
            <strong style="font-size: 9pt;">
              *** NÃO É DOCUMENTO FISCAL ***
            </strong>
          </div>
          <div style="display: flex; width: 100%; justify-content: center;">
            <span style="font-size: 9pt;">
              Realizado
              {{ $moment(lastSell.createdAt).format("DD/MM/YYYY HH:mm") }}
            </span>
          </div>
        </div>
        <div
          style=";display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
        >
          <span>Número: #{{ lastSell.id_transacao }}</span>
        </div>
        <div
          style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
        ></div>
        <div
          v-if="receipt.show_client_details && lastSell.compra.cliente"
          style="display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <div v-if="lastSell.compra.cliente">
            <strong style="margin-right: 6px;">
              Cliente:
            </strong>
            {{ lastSell.compra.cliente.nome }}
          </div>
          <div v-if="lastSell.compra.cliente">
            <strong style="margin-right: 6px;">
              Contato:
            </strong>
            {{ lastSell.compra.cliente.telefone }}
          </div>
          <div v-if="lastSell.compra.cliente">
            <strong style="margin-right: 6px;">
              Whatsapp:
            </strong>
            {{ lastSell.compra.cliente.whatsapp }}
          </div>
        </div>
        <div
          style="display: flex; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <div>
            <strong style="margin-right: 6px;">
              Atendimento:
            </strong>
            {{ lastSell.compra.tipo }}
          </div>
          <div v-if="lastSell.compra.tipo === 'mesa'">
            <strong style="margin-right: 6px;">
              Mesa:
            </strong>
            {{ lastSell.compra?.mesa?.number }}
          </div>
          <div>
            <strong style="margin-right: 6px;">
              Saída:
            </strong>
            {{ lastSell.compra.tipo }}
          </div>
          <div v-if="lastSell.compra.address">
            <strong style="margin-right: 6px;">
              Endereço:
            </strong>
            {{ lastSell.compra.address.formatted_address }}
          </div>
          <div v-if="lastSell.compra.address && lastSell.compra.address.ref">
            <strong style="margin-right: 6px;">
              Referência:
            </strong>
            {{ lastSell.compra.address.ref }}
          </div>
        </div>
        <div
          style="display: flex; margin-top: 12px; margin-bottom: 12px; flex-wrap: wrap; width: 100%;height: 1px; border-bottom: 1px #333 dashed;"
        ></div>
        <div>
          <div
            v-for="(item, i) in lastSell.compra.produtos"
            :key="`product-print-${i}`"
            style="display: flex; flex-direction: column;padding-bottom: 12px; border-bottom: 1px dashed #f2f2f2;"
          >
            {{ item.codigo ? `#${item.codigo}` : "" }} {{ item.quantity }}x
            {{ item.nome }} = {{ $helper.formataSaldo(item.seller_price) }}
            <div
              v-for="(adicional, i2) in item.adicionais"
              :key="`item-ptint-adicional-${i2}`"
              style="display: flex; flex-direction: column;"
              id="adicional"
            >
              <span
                v-for="(item, index) in adicional.itens"
                v-show="
                  item.quantidade_adicionada && item.quantidade_adicionada >= 1
                "
                :key="
                  `inner-adicional-item-${item.descricao_personalizada}-${i2}-${index}`
                "
                style="color: #333; margin-left: 6px;"
              >
                {{ item.quantidade_adicionada }} x
                {{ item.descricao_personalizada }} ({{
                  $helper.formataSaldo(item.preco)
                }})
              </span>
            </div>
            <div id="obs">{{ item.obs }}</div>
          </div>
        </div>
        <div
          style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
        ></div>
        <div
          style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
        >
          <span>(+) Subtotal</span>
          <span>
            {{ $helper.formataSaldo(lastSell.compra.subtotal) }}
          </span>
        </div>

        <div
          v-if="lastSell.compra.address"
          style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
        >
          <span>(+) Taxa de entrega</span>
          <span>
            {{ $helper.formataSaldo(lastSell.compra.address.tax_value || 0) }}
          </span>
        </div>

        <div
          style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
        >
          <span>(+) Adicional</span>
          <span>
            {{ $helper.formataSaldo(lastSell.compra.acrescimo || 0) }}
          </span>
        </div>

        <div
          style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
        >
          <span>(-) Desconto</span>
          <v-spacer></v-spacer>
          <span>
            {{ $helper.formataSaldo(lastSell.compra.desconto || 0) }}
          </span>
        </div>

        <div
          style="font-weight: bold; justify-content: space-between; margin-top: 24px; width: 100%; display: flex;"
        >
          <span>(=) Total do pedido: </span>
          <span>
            {{ $helper.formataSaldo(lastSell.compra.preco_total) }}
          </span>
        </div>
        <div
          style="margin-bottom: 12px; margin-top: 12px; width: 100%; display: flex; height: 1px; border-bottom: 1px #333 dashed;"
        ></div>
        <div
          style="margin-bottom: 12px; justify-content: center; align-items: center; display: flex; width: 100%;"
        >
          <span
            style="text-align: center;"
            v-if="receipt.show_footer_text.active"
          >
            {{ receipt.show_footer_text.text }}
          </span>
        </div>
        <!-- <div
          v-if="pedidoRealizadoPeloMenuRocket"
          style="display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <span style="text-align: center; font-size: 9pt"
            >Pedido realizado pelo</span
          >
          <img
            style="height: 27px; width: 108px;"
            src="img/logo_menu_black.png"
          />
        </div> -->
        <div
          style="display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <img style="height: 6px; width: 54px;" src="img/logo_black.png" />
        </div>
        <div
          style="font-size: 9pt;display: flex; flex-direction: column; flex-wrap: wrap; width: 100%;justify-content: center; align-items: center;"
        >
          market.scnrevolution.com
        </div>
      </div>
    </div>
    <ModalChangeDelivery
      @save="$refs.modalSendToManager.open('quiet')"
      ref="modalChangeDelivery"
      @input="$forceUpdate()"
    />
    <ModalViewClient />
  </layout>
</template>

<script>
import ModalViewAbertura from "../components/modalViewAbertura.vue";
import ModalViewFechamento from "../components/modalViewFechamento.vue";
import ModalAcrescimo from "../components/Acrescimo.vue";
import ModalDesconto from "../components/Desconto.vue";
import ModalFechamento from "../components/ModalFechamento.vue";
import ModalFechamentoId from "../components/ModalFechamentoId.vue";
import ModalSendToManager from "../components/ModalSendToManager.vue";
import ModalAddProduct from "./components/ModalAddProduct.vue";
import ModalChangeTable from "./components/ModalChangeTable.vue";
import ModalViewTable from "./components/ModalViewTable.vue";
import ModalChangeDelivery from "./components/ModalChangeDelivery.vue";
import ModalViewClient from "@/apps/44#Clientes/components/modalView.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/main.js";
import printHtmlToPDF from "print-html-to-pdf";
import Printerr from "../components/printer.vue";
import LottieAnimation from "lottie-web-vue";

export default {
  data() {
    return {
      saleStage: 0,
      inPrinting: false,
      moment: moment,
      printHtmlToPDF: printHtmlToPDF,
      allCategories: true,
      card: false,
      modalAcrescimo: false,
      modalDesconto: false,
      searchCliente: "",
      inputSearch: "",
      mesas_disponiveis: [],
      productSearchList: [],
      categoria_escolhida: {
        preco_multiplo: []
      },
      rotas: [
        {
          name: "Inicio",
          path: "/dashboard"
        },
        {
          name: "PDV",
          path: "/atendimento"
        }
      ],
      options: [
        {
          icon: "mdi-close",
          nome: "Fechar Caixa",
          action: () => this.abre_modal_fechamento_caixa()
        },
        {
          icon: "mdi-open-in-new",
          nome: "Gestor de pedidos ",
          action: () => this.$router.push("/gestordepedidos")
        }
      ],
      lastSell: {
        compra: {
          produtos: [],
          cliente: {},
          address: {}
        }
      },
      payment_sys_config: {},
      receipt: {
        show_my_address: { active: true },
        show_my_logo: { active: true },
        show_my_qrcode: { active: true },
        show_client_details: { active: true },
        show_header_text: { active: true, text: "" },
        show_footer_text: { active: true, text: "" }
      }
    };
  },
  components: {
    ModalViewAbertura,
    ModalViewFechamento,
    ModalDesconto,
    ModalAcrescimo,
    ModalFechamento,
    ModalAddProduct,
    ModalChangeTable,
    ModalChangeDelivery,
    ModalSendToManager,
    Printerr,
    LottieAnimation,
    ModalViewClient,
    ModalFechamentoId,
    ModalViewTable
  },
  watch: {
    inputSearch(val) {
      if (val) {
        this.search();
      } else {
        this.categoria_escolhida = {
          preco_multiplo: []
        };
      }
    }
  },
  computed: {
    pedidoRealizadoPeloMenuRocket() {
      return true;
    },
    ...mapGetters([
      "get_caixa",
      "get_produtos",
      "get_produto",
      "get_compra",
      "get_produtos_filtros",
      "get_caixas",
      "get_clientes",
      "get_clientes_filtros",
      "get_cliente",
      "get_caixas_filtros",
      "getLoggedUser",
      "get_gestordepedidos"
    ]),
    paymentIsOk() {
      if (this.get_compra.status_do_pagamento) {
        if (this.get_compra.status_do_pagamento.toLowerCase() === "pendente") {
          return false;
        }
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    endereco() {
      if (
        this.get_compra &&
        this.get_compra.address &&
        this.get_compra.address.formatted_address
      ) {
        return `End: ${this.get_compra.address.formatted_address}`;
      }
      return "Adicione um endereço";
    },
    updateStatusToFinish(lastSell, print) {
      this.$store.dispatch("enableLoading");
      this.$run("transacoes/update-status-compra", {
        ...this.get_compra,
        new_group_name: "concluído"
      })
        .then(() => {
          this.$refs.modalFechamentoId.close();
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `Pedido finalizado com sucesso`,
            timeout: 6000
          });
          if (print) {
            this.printReceipt(lastSell);
          }
          setTimeout(() => {
            this.limparComanda({ confirmation: true });
          }, 300);
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        })
        .finally(() => {
          this.$store.dispatch("disableLoading");
        });
    },
    updatePayment() {
      this.$store.dispatch("enableLoading");
      this.$run("caixas/update-payment", this.get_gestordepedido)
        .then(res => {
          this.valueToPayInSelectedMethod = "";
          this.type = "";
          this.$store.dispatch("disableLoading");
          this.$store.commit("set_gestordepedido", res.data);
          if (res.data.status_do_pagamento === "pago") {
            this.step = 3;
          } else {
            this.step = 1;
          }
          this.$forceUpdate();
        })
        .catch(e => {
          this.$store.dispatch("disableLoading");
          console.log(e);
        });
    },
    initPaymentId() {
      //validations here pls
      if (this.get_compra.compra.produtos.length) {
        if (!this.$refs.modalFechamentoId.modal) {
          this.$refs.modalFechamentoId.open();
        }
      } else {
        this.createGlobalMessage({
          type: "error",
          message: "Não é possível efetuar uma compra sem produtos!",
          timeout: 6000
        });
      }
    },
    closeExpansionPanelCheckout() {
      if (
        this.$vuetify.breakpoint.smAndDown &&
        this.$refs.expansionPanelHeader
      ) {
        this.$refs.expansionPanelHeader.$emit("click", true);
      }
    },
    selecionarMesa(mesa, save, addClientInTable, pedidoDaMesa) {
      if (!addClientInTable && pedidoDaMesa) {
        // const pedido = this.get_gestordepedidos.docs.find(
        //   p => p.compra.mesa && p.compra?.mesa?.number === mesa?.number
        // );
        // this.get_compra.mesa = mesa;
        // this.$store.commit("set_compra", {
        //   ...pedidoDaMesa.compra,
        //   ...pedidoDaMesa,
        //   compra: {
        //     ...pedidoDaMesa.compra,
        //     mesa,
        //     cliente: pedidoDaMesa.cliente
        //   },
        //   cliente: pedidoDaMesa.cliente,
        //   mesa
        // });
        this.$router.push(`/atendimento?t_id=${pedidoDaMesa._id}`)
        location.reload()
        // this.$store.commit("set_cliente", pedido.cliente)
        // this.$refs.modalViewTable.save = false;
        // this.$refs.modalViewTable.close();
      } else {
        this.$store.commit("set_compra", {
          cliente: {},
          produtos: [],
          tipo: "mesa",
          mesa: mesa,
          subtotal: 0,
          compra: {
            cliente: {},
            produtos: [],
            tipo: "mesa",
            mesa: mesa,
            subtotal: 0
          }
        });
        this.$refs.modalViewTable.close();

      }
      // if (save) {
      //   this.$refs.modalSendToManager.open("quiet");
      // }
      // this.get_gestordepedidos.docs.map(pedido => {
      //   if (pedido.compra.tipo === "mesa") {
      //     if (
      //       pedido.compra.mesa &&
      //       pedido.compra?.mesa?.number === mesa?.number &&
      //       pedido._id
      //     ) {
      //       hasPEdido = true;
      //       this.$run("transacoes/view-transaction", {
      //         _id: pedido._id
      //       }).then(res => {
      //         let transaction = res.data;
      //         delete transaction.caixa;
      //         this.$store.commit("set_compra", {
      //           ...transaction,
      //           ...transaction.compra,
      //           compar: transaction.compra,
      //           status_do_pagamento: transaction.status_do_pagamento,
      //           status_do_pedido: transaction.status_do_pedido
      //         });
      //         this.calcula_valor_total_da_compra();
      //         this.$refs.modalChangeTable.close();
      //       });
      //     }
      //   }
      // });
    },
    trocarMesa(mesa) {
      this.$refs.modalChangeTable.close();
      this.$refs.modalChangeTable.save = false;
      this.get_compra.compra.mesa = mesa;
      this.get_compra.mesa = mesa;
      this.initSendToManager("quiet");
      this.$forceUpdate();
    },
    attachOpenModalAddProduct() {
      if (this.productSearchList.length) {
        this.$refs.modalAddProduct.open(this.productSearchList[0]);
      }
    },
    openModalAddProduct(produto, withIndex) {
      if (produto.is_done) {
        this.createGlobalMessage({
          type: "info",
          message:
            "Este produto já foi marcado como feito no gestor, não pode ser editado ou removido!",
          timeout: 6000
        });
      } else {
        let prod = produto;
        prod.indexProd = parseInt(withIndex);
        this.$refs.modalAddProduct.open(prod);
      }
    },
    setTypeBuy(tipo, first, confirmation) {
      if (
        this.get_compra.tipo !== tipo &&
        !confirmation &&
        this.get_compra._id
      ) {
        this.createConfirmAction({
          message: `O tipo de venda será alterado para "${tipo}", confirma?`,
          icone: "mdi-close-circle",
          isScoped: true,
          action: () => this.setTypeBuy(tipo, false, true),
          action_value: "ok"
        });
        return;
      } else {
        if (this.get_compra.tipo === tipo && this.get_compra.tipo !== "mesa")
          return;

        this.get_compra.tipo = "";
        this.get_compra.tipo = tipo;
        // localStorage.last_type_manager = tipo;
        if (tipo === "mesa") {
          this.$refs.modalViewTable.open("save", confirmation);
        }
        if (tipo === "balcao") {
          delete this.get_compra.mesa;
        }
        if (tipo === "delivery") {
          this.$refs.modalChangeDelivery.open("save", confirmation);
          delete this.get_compra.mesa;
        }
        if (tipo === "retirada") {
          delete this.get_compra.mesa;
        }
        if (confirmation && this.get_compra._id) {
          this.$refs.modalSendToManager.open("quiet");
        }
        this.$forceUpdate();
      }
    },
    setCategory(categoria) {
      if (categoria) {
        this.categoria_escolhida = categoria;
        this.allCategories = false;
      } else {
        this.allCategories = true;
        this.categoria_escolhida = {
          preco_multiplo: []
        };
      }
    },
    ...mapActions([
      "createGlobalMessage",
      "add_produto_a_compra",
      "salvar_compra",
      "listar_gestordepedidos",
      "finalizar_compra",
      "iniciar_pedido",
      "abre_modal_view_caixa",
      "abre_modal_abertura_caixa",
      "abre_modal_fechamento_caixa",
      "calcula_valor_total_da_compra",
      "listar_caixas",
      "listar_clientes",
      "listar_produtos",
      "listar_produtocategorias",
      "remove_produto_da_compra",
      "createConfirmAction",
      "abre_modal_view_cliente"
    ]),
    finishSell() {
      this.finalizar_compra();
    },
    printReceipt(compra) {
      this.lastSell = compra;
      this.inPrinting = true;
      this.$forceUpdate();
      setTimeout(() => {
        var mywindow = window.open("", "PRINT", "height=400,width=600");

        mywindow.document.write(
          "<html><head><title>" + document.title + "</title>"
        );
        mywindow.document.write("</head><body >");
        mywindow.document.write(
          document.getElementById("print-html").innerHTML
        );
        mywindow.document.write("</body></html>");

        // mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        // mywindow.close();
      }, 300);
      setTimeout(() => {
        this.inPrinting = false;
        this.closeExpansionPanelCheckout();
      }, 500);
    },
    search() {
      this.productSearchList = [];
      this.get_produtos.docs.map(product => {
        if (Array.isArray(product.preco_multiplo)) {
          product.preco_multiplo.map(preco => {
            if (preco.disponivel) {
              if (
                preco.nome
                  .toLowerCase()
                  .includes(this.inputSearch.toLowerCase())
              ) {
                this.productSearchList.push(preco);
              } else if (
                preco.code &&
                preco.code
                  .toLowerCase()
                  .indexOf(this.inputSearch.toLowerCase()) > -1
              )
                this.productSearchList.push(preco);
            }
          });
        }
      });
    },
    setModalAddProduto(produto, withIndex) {
      let prod = produto;
      prod.indexProd = parseInt(withIndex);
      this.$store.commit("set_produto", prod);
      this.modalStageProduct = true;
    },
    addProductToCart(product) {
      if (product.indexProd === 0 || product.indexProd > 0) {
        this.get_compra.produtos[product.indexProd] = product;
      } else {
        this.get_compra.produtos.push(product);
      }
      this.$refs.modalAddProduct.close();
      // this.createGlobalMessage({
      //   type: "success",
      //   message: "Produto adicionado com sucesso!",
      //   timeout: 6000
      // });
      this.calcula_valor_total_da_compra();
    },
    removeProductOfCart(product) {
      if (product.indexProd === 0 || product.indexProd > 0) {
        let new_products = [];
        this.get_compra.produtos.map((p, i) => {
          if (i !== product.indexProd) {
            new_products.push(p);
          }
        });
        this.get_compra.produtos = new_products;
        this.createGlobalMessage({
          type: "success",
          message: "Produto removido com sucesso!",
          timeout: 6000
        });
        this.$forceUpdate();
      }
      this.$refs.modalAddProduct.close();
      this.calcula_valor_total_da_compra();
    },
    //somenta para pagamento rápido, que agora é o botão pagar.
    async initPayment() {
      if (
        !this.get_compra.tipo ||
        this.get_compra.tipo === "undefined" ||
        this.get_compra.tipo === undefined
      ) {
        this.get_compra.tipo = "balcao";
      }
      //validations here pls
      if (this.get_compra.produtos.length) {
        await this.iniciar_pedido();
        if (!this.$refs.modalFechamentoId.modal) {
          this.$refs.modalFechamentoId.open();
        }
      } else {
        this.createGlobalMessage({
          type: "error",
          message: "Não é possível efetuar uma compra sem produtos!",
          timeout: 6000
        });
      }
    },
    initSendToManager(option) {
      //validations here pls
      const isClientEmpty =
        Object.keys(this.get_compra.cliente || {}).length === 0;
      let isAddressEmpty;
      if (this.get_compra.tipo) {
        if (this.get_compra.address) {
          isAddressEmpty = !this.get_compra.address.formatted_address;
        }
      }
      if (
        !this.get_compra.tipo ||
        this.get_compra.tipo === "undefined" ||
        this.get_compra.tipo === undefined
      ) {
        this.createGlobalMessage({
          type: "error",
          message: "Informe se é mesa, balcão, delivery ou retirada!",
          timeout: 6000
        });
        return;
      }
      if (
        this.get_compra.tipo === "delivery" &&
        (isAddressEmpty || isClientEmpty)
      ) {
        let emptyText = "";
        if (isAddressEmpty) {
          emptyText = "endereço";
        } else if (isClientEmpty) {
          emptyText = "cliente";
        }
        this.createGlobalMessage({
          type: "error",
          message: `Informe um ${emptyText}!`,
          timeout: 6000
        });
        if (isAddressEmpty) {
          this.$refs.modalChangeDelivery.open();
        }
        return;
      }
      if (this.get_compra.tipo === "mesa" && !this.get_compra.mesa) {
        this.createGlobalMessage({
          type: "error",
          message: "Informe uma mesa!",
          timeout: 6000
        });
        this.$refs.modalChangeTable.open();
        return;
      }
      if (this.get_compra.produtos.length) {
        if (!this.$refs.modalSendToManager.modal) {
          this.$refs.modalSendToManager.open();
        }
      } else {
        this.createGlobalMessage({
          type: "error",
          message: "Adicione produtos antes!",
          timeout: 6000
        });
      }
    },
    limparComanda(item) {
      if (item && item.confirmation) {
        this.$store.commit("set_compra", {
          cliente: {},
          produtos: [],
          tipo: "balcao",
          subtotal: 0
        });
        this.$router.push("/atendimento");
        this.$forceUpdate();
      } else {
        this.createConfirmAction({
          message:
            "Deseja limpar a comanda? Isso irá apagar todos os produtos da comanda e zerar o valor.",
          icone: "mdi-close-circle",
          isScoped: true,
          action: () => this.limparComanda({ confirmation: true }),
          action_value: "ok"
        });
      }
    },
    salvarCompra() {
      this.salvar_compra();
    },
    getConfigPaymentSys() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-payment-sys")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.payment_sys_config = res.data.payment_sys_config;
        })
        .catch(e => {});
    },
    getConfigReceipt() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-receipt")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.receipt = res.data.receipt;
        })
        .catch(e => {});
    }
  },
  created() {
    this.get_caixas_filtros.funcionario = this.getLoggedUser;
    this.get_caixas_filtros.meu_caixa = true;
    this.get_caixas_filtros.filter = true;
    this.listar_caixas();
    this.listar_produtos();
    this.getConfigPaymentSys();
    this.getConfigReceipt();
    this.listar_gestordepedidos();
    // this.listar_produtocategorias();
    this.listar_clientes();
    this.$run("marketplaces/get-config-local").then(
      res => (this.mesas_disponiveis = res.data.local.list_tables)
    );
  },
  mounted() {
    // this.setTypeBuy(localStorage.last_type_manager, true);
    sessionStorage.scope_level = "atendimento";
    EventBus.$on("escape", () => {
      this.$refs.searchField.focus();
    });
    // EventBus.$on("ArrowRight", () => {
    //   if (sessionStorage.scope_level === "atendimento") {
    //     this.initPayment();
    //   }
    // });
    EventBus.$on("print_compra", compra => this.printReceipt(compra));
    EventBus.$on("open-payment", () => this.initPaymentId());
    if (this.$route.query.t_id) {
      this.$run("transacoes/view-transaction", {
        _id: this.$route.query.t_id
      }).then(res => {
        let transaction = res.data;
        if (transaction.status_do_pedido == 'concluído'){
          this.$router.push("/atendimento");
          return;
        }
        delete transaction.caixa;
        this.$store.commit("set_compra", {
          ...transaction,
          ...transaction.compra,
          compra: transaction.compra,
          status_do_pagamento: transaction.status_do_pagamento,
          status_do_pedido: transaction.status_do_pedido
        });
        this.calcula_valor_total_da_compra();
      });
    }
  },
  beforeDestroy() {
    sessionStorage.scope_level = "";
    EventBus.$off("escape");
    EventBus.$off("ArrowRight");
    EventBus.$off("print_compra");
  }
};
</script>

<style scoped>
.item_caixa {
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 6px;
  background: #333;
  color: #f2f2f2;
}

.caixa_font_color {
  color: #fff;
}

.caixa-status--fechado {
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 6px;
  background: rgb(236, 82, 82);
}
.caixa-status--aberto {
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 6px;
  background: green;
}

.activeCategory {
  background-color: #2cc4ea;
}
.font-green {
  color: #2cc4ea;
}
.item-list-product {
  background: #f2f2f2;
  border-radius: 6px;
  margin-bottom: 6px;
}
.max-height-list-products {
  max-height: 67vh;
}

.max-height-list-products::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.max-height-list-products::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.max-height-list-products::-webkit-scrollbar-thumb {
  background-color: #999; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.scroller-items::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.scroller-items::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.scroller-items::-webkit-scrollbar-thumb {
  background-color: #999; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.max-height-list-products-mobile {
  max-height: 60vh;
}
.divider {
  margin-top: 12px;
  margin-bottom: 12px;
}
.product-done {
  background: rgb(244, 167, 116) !important;
}
.nobg {
  background: #fff;
  background-color: #fff;
}
.img-icon {
  width: 40px;
  border-radius: 3px;
}
.list-box-to-open {
  background: #673AB7;
  margin-bottom: 12px;
  border-radius: 6px;
}
</style>
